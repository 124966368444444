@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url('./fonts/karla-v14-latin-ext_latin-regular.eot'); /* IE9 Compat Modes */
  src: local(''),
    url('./fonts/karla-v14-latin-ext_latin-regular.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./fonts/karla-v14-latin-ext_latin-regular.woff2')
      format('woff2'),
    /* Super Modern Browsers */
      url('./fonts/karla-v14-latin-ext_latin-regular.woff') format('woff'),
    /* Modern Browsers */ url('./fonts/karla-v14-latin-ext_latin-regular.ttf')
      format('truetype'),
    /* Safari, Android, iOS */
      url('./fonts/karla-v14-latin-ext_latin-regular.svg#Karla') format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

@import url('http://fonts.googleapis.com/css?family=Cousine&subset=latin,latin-ext');
@import url('http://fonts.googleapis.com/css?family=Karla&subset=latin,latin-ext');

.App {
  text-align: left;
  font-family: Karla, sans-serif;
  color: #264653;
  background: #fffaf2;
}

.navbar-brand {
  text-align: left;
  font-family: Karla, sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: #f4a261;
}

.nav-link {
  text-align: left;
  font-family: Karla, sans-serif;
  font-weight: 600;
  color: #2a9d8f;
}

.navbar-toggler {
  color: black;
}

/* .Ragam {
  color: black;
  font-family: Karla, sans-serif;
} */

.splashCards {
  padding: 1em;
}

.logo {
  width: 40%;
  height: auto;
}

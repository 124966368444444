@import url(http://fonts.googleapis.com/css?family=Cousine&subset=latin,latin-ext);
@import url(http://fonts.googleapis.com/css?family=Karla&subset=latin,latin-ext);
@font-face {
  font-family: 'Karla';
  font-style: normal;
  font-weight: 400;
  src: url(/static/media/karla-v14-latin-ext_latin-regular.220890ca.eot); /* IE9 Compat Modes */
  src: local(''),
    url(/static/media/karla-v14-latin-ext_latin-regular.220890ca.eot?#iefix)
      format('embedded-opentype'),
     url(/static/media/karla-v14-latin-ext_latin-regular.57a47bef.woff2)
      format('woff2'),
    
      url(/static/media/karla-v14-latin-ext_latin-regular.55cad43c.woff) format('woff'),
     url(/static/media/karla-v14-latin-ext_latin-regular.2b46d268.ttf)
      format('truetype'),
    
      url(/static/media/karla-v14-latin-ext_latin-regular.da5a91a3.svg#Karla) format('svg'); /* Legacy iOS */
}

body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.App {
  text-align: left;
  font-family: Karla, sans-serif;
  color: #264653;
  background: #fffaf2;
}

.navbar-brand {
  text-align: left;
  font-family: Karla, sans-serif;
  font-size: 2em;
  font-weight: 600;
  color: #f4a261;
}

.nav-link {
  text-align: left;
  font-family: Karla, sans-serif;
  font-weight: 600;
  color: #2a9d8f;
}

.navbar-toggler {
  color: black;
}

/* .Ragam {
  color: black;
  font-family: Karla, sans-serif;
} */

.splashCards {
  padding: 1em;
}

.logo {
  width: 40%;
  height: auto;
}

